import * as React from "react";
import { PortfolioNav } from "../../components";
import styled from "styled-components";

// const videos = [
//   { url: 'https://www.youtube.com/embed/1drD0bIke6E', title: 'Adobe Samples'},
//   { url: 'https://www.instagram.com/tv/Cb-wlf3tZGf/embed', title: 'BNG RUMBLE PONIES'},
//   // { url: 'https://www.tiktok.com/embed/6849453905462512902', title: 'TIKTOK'}
// ]

const PortfolioPage = () => {
  return (
    <PortfolioContainer>
      <div className="colored">
        <h1>Portfolio</h1>
      </div>
      <PortfolioNav />
    </PortfolioContainer>
  );
};

export default PortfolioPage;

export const Head = () => <title>TMOY || Portfolio</title>;

const PortfolioContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  padding: 1rem;
  margin-bottom: 2rem;
  gap: 10px;
  max-width: 1000px;

  .colored {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem 0;
    width: 100vw;
    background-color: ${props => props.theme.text};
    background-image: url("images/city.jpg");
    background-position: center;
    background-size: cover;
    margin-bottom: 2rem;
    margin-top: -1rem;
  }

  h1 {
    color: ${props => props.theme.bg};
  }
`;
